// import React from 'react';
// import './print.css';
// import moment from 'moment'; // Ensure moment is installed

// const PrintComponent = ({ formStructure, transactionData, template, onClose }) => {
//   console.log("Transaction Print", transactionData, formStructure);

//   if (!formStructure || !transactionData || !template) {
//     return <div>No data available or invalid data structure.</div>;
//   }

//   const { form_fields = [], form_grids = [], name: formName } = formStructure;
//   const { reportHeader, pageHeader, details, pageFooter, reportFooter, reportFooterText } = template;

//   const handlePrint = () => {
//     const printWindow = window.open("", "_blank");
//     const printContent = document.getElementById("printableContent").innerHTML;

//     printWindow.document.open();
//     printWindow.document.write(`
//       <html>
//         <head>
//           <title>${formName || 'Print'}</title>
//           <style>
//             body { font-family: Arial, sans-serif; margin: 0; padding: 10px; }
//             table { width: 100%; border-collapse: collapse; margin-top: 10px; }
//             th, td { border: 1px solid black; padding: 8px; text-align: left; }
//           </style>
//         </head>
//         <body onload="window.print(); window.close();">
//           ${printContent}
//         </body>
//       </html>
//     `);
//     printWindow.document.close();
//   };

//   const getNestedValue = (key, obj = transactionData) => {
//     const searchKey = (obj) => {
//       if (Array.isArray(obj)) {
//         for (let item of obj) {
//           const result = searchKey(item);
//           if (result !== undefined) return result;
//         }
//       } else if (typeof obj === 'object' && obj !== null) {
//         if (key in obj) return obj[key];
//         for (let k in obj) {
//           const result = searchKey(obj[k]);
//           if (result !== undefined) return result;
//         }
//       }
//       return undefined;
//     };
//     return searchKey(obj) || 'N/A';
//   };

//   const formatNumber = (number, pattern) => {
//     const [integerPart, decimalPart] = pattern.split('.');
//     const decimalPlaces = decimalPart ? decimalPart.length : 0;

//     const formattedNumber = number
//       .toFixed(decimalPlaces) // Ensure the number has the correct decimal places
//       .replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas as thousand separators

//     return formattedNumber;
//   };

//   const renderFormFields = () =>
//     form_fields
//       .filter((field) => field.visible)
//       .map((field) => {
//         const { name, label } = field;
//         const value = getNestedValue(name);

//         return (
//           <tr key={field.name}>
//             <th style={{ textAlign: 'left', padding: '8px' }}>{label}</th>
//             <td style={{ padding: '8px' }}>{value}</td>
//           </tr>
//         );
//       });

//   const renderFormGrids = () => {
//     const visibleGrids = form_grids?.filter((grid) => grid.visible);
//     if (!visibleGrids?.length) return null;

//     console.log("Grids data", form_grids);

//     const gridData = Array.isArray(transactionData[0]?.transaccounts)
//       ? transactionData[0].transaccounts
//       : transactionData;

//     return (
//       <table id="reporttable" className="grid-table">
//         <thead>
//           <tr>
//             {visibleGrids?.map((grid) => (
//               <th key={grid.id || grid.name}>{grid.label}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {gridData?.map((item, index) => (
//             <tr key={index}>
//               {visibleGrids?.map((grid) => {
//                 const value = item[grid.name] || 'N/A';

//                 // Apply formatting based on input type
//                 const formattedValue =
//                   grid.inputtype === 'Number' && value !== 'N/A'
//                     ? formatNumber(parseFloat(value), grid.dataformat)
//                     : value;

//                 return (
//                   <td
//                     key={grid.id || grid.name}
//                     style={{
//                       textAlign: grid.inputtype === 'Number' ? 'right' : 'left',
//                       padding: '8px',
//                       maxWidth: '150px',
//                     }}
//                   >
//                     {formattedValue}
//                   </td>
//                 );
//               })}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     );
//   };

//   return (

//     <div style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto', maxHeight: '50vh' }}>
//       <div id="printableContent">
//         {reportHeader && (
//           <div className="report-header">
//             <table className="report-table">
//               <tbody>{renderFormFields()}</tbody>
//             </table>
//           </div>
//         )}

//         {pageHeader && <div className="page-header"></div>}

//         {details && <div className="details">{renderFormGrids()}</div>}

//         {pageFooter && <div className="page-footer">{pageFooter}</div>}
//         {reportFooter && <div className="report-footer">{reportFooterText}</div>}
//       </div>

//       <div className='static'>
//         <div className="printdiv  ">
//           <button className="printbtn" onClick={handlePrint}>
//             Print
//           </button>
//           <button className="printbtn" onClick={onClose}>
//             Close
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PrintComponent;
import React, { useState, useEffect } from 'react';
import numeral from 'numeral';
import logo from '../../asset/printlogo.jpg';

import './print.css';
import { toWords } from 'number-to-words';

const PrintComponent = ({ formStructure, transactionData, template, onClose }) => {
  const [amountInWords, setAmountInWords] = useState("");
console.log("trdata",transactionData);
let totalAmount;
  useEffect(() => {
    if (!formStructure || !transactionData || !template) return;

    // Calculate the total amount based on the field in totals
    const gridData = Array.isArray(transactionData[0]?.transaccounts)
      ? transactionData[0].transaccounts
      : transactionData;
    const totals = {};
console.log("Grd",gridData);
    formStructure.form_grids?.forEach((grid) => {
      if (grid.inputtype === 'Number') {
        totals[grid.name] = gridData.reduce((sum, item) => {
          const value = Number(String(item[grid.name]).replace(/,/g, '')) || 0;
          return sum + value;
        }, 0);
      }
    });

     totalAmount = totals['txn_amount'] || 0; // Access the specific field in totals, e.g., 'txn_amount'
    //   setAmountInWords(toWords(totalAmount)); // Convert total amount to words and set in state
    // }, [transactionData, formStructure]);
    // Convert to words and capitalize each word
   
    const rawAmountInWords = toWords(Math.floor(totalAmount)); // Remove decimal part
    const formattedAmountInWords = rawAmountInWords
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .replace(/,/g, ''); // Remove all commas

    // Add "Only" at the end
    setAmountInWords(`${formattedAmountInWords} Only`);
  }, [transactionData, formStructure]);
  console.log("Total",totalAmount)
  // Early return for invalid data
  if (!formStructure || !transactionData || !template) {
    return <div>No data available or invalid data structure.</div>;
  }

  const templateStyles = template.styles || {};
  const { form_fields = [], form_grids = [], name: formName } = formStructure;

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');

    // Safely access the innerHTML content, using optional chaining to avoid errors
    const printContent = document.getElementById('printlogo')?.innerHTML || '';
    const pgheader1 = document.getElementById('pageheader1')?.innerHTML || '';
    const pgheader2 = document.getElementById('pageheader2')?.innerHTML || '';

    const details = document.getElementById('detailtable')?.innerHTML || '';
    const details1 = document.getElementById('detailtablefooter')?.innerHTML || '';
    //const details2 = document.getElementById('detailtablefooter1')?.innerHTML || '';

    const footer = document.getElementById('footer')?.innerHTML || '';

    // Write HTML content to the new print window
    printWindow.document.open();
    printWindow.document.write(`
      <!DOCTYPE html>
      <html>
        <head>
          <title>Print</title>
          <link rel="stylesheet" type="text/css" href="print.css">
        </head>
        <body>
          <div>${printContent}</div>
          <div>${pgheader1}</div>
          <div>${pgheader2}</div>
          <div>${details}</div>
          <div>${details1}</div>
         
          <div>${footer}</div>
        </body>
      </html>
    `);
    printWindow.document.close();

    // Ensure the print window has loaded before printing and closing
    printWindow.onload = () => {
      printWindow.print();
      printWindow.close();
    };
  };



  const getNestedValue = (key, obj = transactionData) => {
    const searchKey = (obj) => {
      if (Array.isArray(obj)) {
        for (let item of obj) {
          const result = searchKey(item);
          if (result !== undefined) return result;
        }
      } else if (typeof obj === 'object' && obj !== null) {
        if (key in obj) return obj[key];
        for (let k in obj) {
          const result = searchKey(obj[k]);
          if (result !== undefined) return result;
        }
      }
      return key;
    };
    return searchKey(obj) || 'N/A';
  };

  const formatData = (value, field) => {
    if (!value || !field.dataformat) return value;
    switch (field.inputtype) {
      case 'Number': {
        const num = Number(String(value).replace(/,/g, ''));
        if (isNaN(num)) return value;
        const decimalPlaces = (field.dataformat.split('.')[1] || '').length;
        const [intPart, decPart] = num.toFixed(decimalPlaces).split('.');
        const groups = field.dataformat.split('.')[0].split(',');
        let formatted = '';
        let remaining = intPart;

        for (let i = groups.length - 1; i >= 0; i--) {
          const size = groups[i].length;
          if (remaining.length > size) {
            formatted = ',' + remaining.slice(-size) + formatted;
            remaining = remaining.slice(0, -size);
          } else {
            formatted = remaining + formatted;
            remaining = '';
            break;
          }
        }

        formatted = formatted.replace(/^,/, '');
        return decPart ? `${formatted}.${decPart}` : formatted;
      }
      default:
        return value;
    }
  };
  const totals = {};
  const renderFormGrids = () => {
    const visibleGrids = form_grids?.filter((grid) => grid.visible);
    if (!visibleGrids?.length) return null;

    const gridData = Array.isArray(transactionData[0]?.transaccounts)
      ? transactionData[0].transaccounts
      : transactionData;

    
    console.log("Visible GRid",visibleGrids);
    visibleGrids.forEach((grid) => {
      if (grid.inputtype === 'Number') {
        totals[grid.name] = gridData.reduce((sum, item) => {
          const value = Number(String(item[grid.name]).replace(/,/g, '')) || 0;
          return sum + value;
        }, 0);
      }
    });

    return (
      <div style={templateStyles.details.container} >
        <div style={templateStyles.details.tableContainer} id="detailtable">
          <table style={templateStyles.details.table}>
            <thead style={templateStyles.details.thead}>
              <tr>
                <th style={templateStyles.details.headerCell}>
                  SN
                </th>
                {visibleGrids.map((grid) => (
                  <th
                    key={grid.name}
                    style={templateStyles.details.headerCell}
                  >
                    {grid.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {gridData.map((item, index) => (
                <tr key={index}>
                  <td style={templateStyles.details.bodyCell}>
                    {index + 1}
                  </td>
                  {visibleGrids.map((grid) => {
                    let value = grid.jsonref
                      ? JSON.parse(item[grid.jsonref])[grid.name]
                      : item[grid.name];
                    return (
                      <td
                        key={grid.name}
                        style={templateStyles.details.bodyCell}
                      >
                        {formatData(value, grid)}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div style={templateStyles.details.footer} id="detailtablefooter">
          <table style={templateStyles.details.footerTable} >
            <tfoot>
              <tr>
                <td style={templateStyles.details.footerCell} id="detailtablefooter1">
                  Total
                </td>
                {visibleGrids.map((grid) => (
                  <td
                    key={grid.name}
                    style={templateStyles.details.footerCell}
                  >
                    {grid.inputtype === 'Number' ? formatData(totals[grid.name], grid) : ''}
                  </td>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  };

console.log("Total",totalAmount)
  return (
    <div>
      <div id="printableContent" style={{ maxWidth: template.layout.maxWidth, margin: template.layout.margin, backgroundColor: template.layout.backgroundColor, boxShadow: template.layout.boxShadow }}>
        <div style={templateStyles.reportHeader}>
          <div id="printlogo" style={templateStyles.reportHeader.logoSection}>
            <img src={logo} alt="Milestone Logo" style={templateStyles.reportHeader.printlogo} />
          </div>
          <div>
            <span
              style={{
                fontSize: template.styles.reportHeader.companyName.fontSize,
                fontWeight: template.styles.reportHeader.companyName.fontWeight,
                color: template.styles.reportHeader.companyName.color,
                marginBottom: template.styles.reportHeader.companyName.marginBottom
              }} ></span>
          </div>
        </div>

        {template.pageHeader && (

          <div style={templateStyles.pageHeader} id="pageheader1">
            <div>
              <div style={templateStyles.pageHeader.header}>
                <span >{formName || 'Print'}</span>
              </div>

              {/* <div> <div >
                <strong style={{ marginBottom: templateStyles.pageHeader.billTo.title.marginBottom }}></strong>
              </div></div> */}
              <div
                className='grid grid-cols-2 gap-2'
                style={{
                  fontSize: templateStyles.pageHeader.invoiceDetails.fontSize
                }}
              >
                {form_fields.filter((field) => field.visible).map((field, index) => {
                  const value = getNestedValue(field.name);
                  const isLeftColumn = index % 2 === 0;

                  return (
                    <div
                      key={field.name}
                      className={isLeftColumn ? 'text-left' : 'text-right'}
                    >
                      <strong>{field.label}:</strong> {formatData(value, field)}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

        )}
        {template.details && <div className="details" style={templateStyles.details} id="details">{renderFormGrids()}</div>}

        {template.reportFooter && (
          <div style={templateStyles.reportFooter} id="footer">
            <div style={templateStyles.reportFooter.amountInWords}>{amountInWords}</div>
            <div style={templateStyles.reportFooter.totals}>
              {/* <h1>50500</h1> */}
              <span>Total:</span>
              <span>{totalAmount}</span>
              
              {['subtotal', 'tax', 'total'].map(fieldName => {
                const field = form_fields.find(f => f.name === fieldName);
                if (field) {
                  return (

                    <div key={fieldName} style={templateStyles.reportFooter.totals.row}>

                      
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        )}

        {template.pageFooter && (
          <div style={templateStyles.pageFooter}>
            
            <p>{template.pageFooter.text}</p>
            
          </div>
        )}

      </div>
      <div className='static'>
        <div className="printdiv">
          <button className="printbtn" onClick={handlePrint}>Print</button>
          <button className="printbtn" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default PrintComponent;
