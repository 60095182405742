import axios from 'axios';
import { BaseURL, refreshToken } from './Components/Masters/masterPagefunctions';


const getMenuImageStructure = async (setLogoutModalExRefreshToken) => {
    const email = sessionStorage.getItem('email')
    try {
        const accessToken = await refreshToken(setLogoutModalExRefreshToken);
        if (!accessToken) {
            return; // Logout modal will be triggered
        }
        let response
     
            response = await axios.get(`https://confighub.${BaseURL}/api/v1/images`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    identifier: email
                },
            });

        if (response.status === 200) {
            return response.data;
        } else {
            console.error('Request failed with status:', response.status);
            return [];
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
};

export const reduceMenuData = async (setLogoutModalExRefreshToken) => {
    const menuImage = await getMenuImageStructure(setLogoutModalExRefreshToken);
    const images = {};

    const processedData = await Promise.all(menuImage.map(async (item) => {
        try {
            const image = await import(`../src/asset/Menusvg/main/${item.imagename}`);
            images[item.key] = image.default;
        } catch (error) {
            console.error(`Error importing image: ${item.key}`, error);
        }
        return { image: images[item.key], name: item.imagename };
    }));

    console.log('Processed Data:', processedData);
    console.log('Images:', images);

    return processedData;
};






