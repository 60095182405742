import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { BaseURL, refreshToken } from '../Masters/masterPagefunctions';
import './chartofAccount.css'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius:'20px',
    boxShadow: 24,
    // p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
};

export default function AddChartofAccount({ setLogoutModalExRefreshToken, parentId, setInsertData, chartNameImage,formDetails, accountData,setModalOpenState }) {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const [success, setSuccess] = React.useState(false);

    const email = sessionStorage.getItem('email');

    const handleOpen = () => {
        setModalOpenState(true);
        setOpen(true);
        if (chartNameImage?.name !== "Insert") {
            setName(accountData?.name || ''); // Pre-fill input for edit
        }
    };

    const handleClose = () => {
        setModalOpenState(false);
        setOpen(false);
        setName('');
        setError('');
        setSuccess(false);
    };

    const handleAddOrEditLevels = async (e) => {
        e.preventDefault();
        if (!name.trim()) {
            setError('Level name is required.');
            return;
        }

        setLoading(true);
        setError('');
        const accessToken = await refreshToken(setLogoutModalExRefreshToken);
        if (!accessToken) {
            setLoading(false);
            return; // Logout modal will be triggered
        }

        try {
            const url = chartNameImage?.name === "Insert" ?  `https://${formDetails?.apiservicename}.${BaseURL}${formDetails?.apiendpoint}`: `https://${formDetails?.apiservicename}.${BaseURL}${formDetails?.apiendpoint}?id=${accountData?.id}`
            const payload = chartNameImage?.name === "Insert"
                ? { name, parentid: parentId }
                : { name}; // Include ID for editing

            const method = chartNameImage?.name === "Insert" ? axios.post : axios.put;

            const response = await method(
                url,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        identifier: email,
                    },
                }
            );

            console.log('Modal console', response);
            setInsertData(response.data);
            setSuccess(true);
            setTimeout(handleClose, 2000); // Close modal after 2 seconds
        } catch (error) {
            console.error('Error:', error);
            setError('Failed to save level. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        setLoading(true);
        setError('');
        const accessToken = await refreshToken(setLogoutModalExRefreshToken);
        if (!accessToken) {
            setLoading(false);
            return; // Logout modal will be triggered
        }

        try {
            const response = await axios.delete(
                `https://${formDetails?.apiservicename}.${BaseURL}${formDetails?.apiendpoint}?id=${accountData?.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        identifier: email,
                    },
                }
            );
            console.log('Delete response:', response);
            setInsertData(response.data);
            setSuccess(true);
            setTimeout(handleClose, 2000); // Close modal after 2 seconds
        } catch (error) {
            console.error('Error:', error);
            setError('Failed to delete account. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <button className="p-2" onClick={handleOpen}>
                <img src={chartNameImage?.image} alt="" width="12px" />
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='py-3 alerBgColor w-full' style={{borderTopLeftRadius:'20px',borderTopRightRadius:"20px"}}>
                    </div>
                    <Typography id="modal-modal-title" variant="h6" component="h2" align='center' style={{fontWeight:'600',color:'black'}}>
                        {chartNameImage?.name === "Insert"
                            ? 'Add Account'
                            : chartNameImage?.name === "Delete"
                                ? 'Delete Account'
                                : 'Edit Account'}
                    </Typography>
                    {chartNameImage?.name === "Delete" ? (
                        <div className='pb-3'>

                            <Typography align='center' style={{fontWeight:'500',color:'black'}}>Are you sure you want to delete this account?</Typography>
                            {error && <Typography color="error" align='center'>{error}</Typography>}
                            {success && <Typography color="success">Account deleted successfully!</Typography>}
                            <div style={{ display: 'flex',justifyContent:'space-around'}}>
                                <Button onClick={handleDelete} disabled={loading} style={{fontWeight:'600',color:'black'}}>
                                    {loading ? 'Deleting...' : 'Delete'}
                                </Button>
                                <Button onClick={handleClose} style={{fontWeight:'600',color:'black'}}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <form onSubmit={handleAddOrEditLevels} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <input
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="outline-blue-200 border border-indigo-600 rounded p-2"
                                placeholder="Enter Account"
                                style={{margin:'0px 10px'}}
                                required
                            />
                            {error && <Typography color="error">{error}</Typography>}
                            {success && <Typography color="success">Level saved successfully!</Typography>}
                            <Button type="submit" className='mb-3 alerBgColor text-white py-1' disabled={loading} style={{padding:'0px 10px',width:"35%",margin:'auto'}}>
                                {loading ? 'Submitting...' : 'Submit'}
                            </Button>
                        </form>
                    )}
                </Box>
            </Modal>
        </div>
    );
}

