import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { darkTheme, dayTheme } from '../../PageStyle/colorsdarkWhite';
import axios from 'axios';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DescriptionIcon from '@mui/icons-material/Description';
import AddChartofAccount from './AddChartofAccount';
import { BaseURL, refreshToken } from '../Masters/masterPagefunctions';
import { useSetState } from '@mantine/hooks';
import Edit from '../../asset/Menusvg/main/update.svg';
import Delete from '../../asset/Menusvg/main/delete.svg';
import Insert from '../../asset/Menusvg/main/insert icon.svg'
import BeforeLoadTableData from '../CommanCoponent/BeforeLoadTableData';





function ChartAccounts({ formDetails,hiddenReloadIcon,sethiddenReloadIcon, setLogoutModalExRefreshToken }) {
    const arabicAlignMent = useSelector((state) => state.arabicAlignMent);
    const darkModeState = useSelector((state) => state.darkMode);

    const [chartLevels, setChartLevels] = useState([]);
    const [activeItems, setActiveItems] = useState({});
    const [hoveredItems, setHoveredItems] = useState({});
    const [loadingLevel, setLoadingLevel] = useState(null);
    const [insertData, setInsertData] = useSetState([])
    const [modalOpenState, setModalOpenState] = useSetState(false)
    const [action, setaction] = useState([
        {
            name: "Insert",
            image: Insert
        },
        {
            name: "Edit",
            image: Edit
        },
        {
            name: "Delete",
            image: Delete
        },

    ])
    const levelRefs = useRef([]); // Refs for each level // Track hover state for each item

    const getEmail = sessionStorage.getItem('email');

    // console.log("apiendpint",formDetails?.apiendpoint);
    // console.log("apisevicename",formDetails?.apiservicename);
    // console.log("apiargument",formDetails?.apiarguments?.[0].split("="));


    const apiargument = formDetails?.apiarguments?.map((item, index) => {
        const [name, equation] = item?.split('='); // Split the string into name and equation
        return { name, equation, index: index }; // Construct the desired object
    });

    console.log("Transformed api arguments:", apiargument);



    const getChartOfAccount = async (level, parentId) => {



        try {
            // if (modalOpenState) return;

            const accessToken = await refreshToken(setLogoutModalExRefreshToken);
            if (!accessToken) {
                return; // Logout modal will be triggered
            }


            let response;

            response = await axios.get(
                `https://${formDetails?.apiservicename}.${BaseURL}${formDetails?.apiendpoint}?${apiargument?.[0].name}=${level}&${apiargument?.[1].name}=${parentId}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        identifier: getEmail,
                    },
                }
            );



            const allData = response.data.data?.[0]?.active || [];
            return allData
        } catch (error) {
            console.error('Error fetching chart of accounts:', error);
            return [];
        }
    };

    useEffect(() => {
        // if (modalOpenState) return;
        (async () => {

            setLoadingLevel(1); // Mark level 1 as loading
            const initialData = await getChartOfAccount(1, null);
            setChartLevels([{ level: 1, data: initialData }]);
            setLoadingLevel(null); // Mark level 1 as loading
        })();
    }, [insertData]);

    const handleItemClick = async (item) => {
        const nextLevel = item.level + 1;
        setActiveItems((prev) => ({ ...prev, [item.level]: item.id }));

        if (!item?.isgroup) return;
        setLoadingLevel(nextLevel); // Mark the next level as loading
        const newData = await getChartOfAccount(nextLevel, item.id);
        setLoadingLevel(null);
        setChartLevels((prevLevels) => {
            const updatedLevels = [...prevLevels];
            updatedLevels[item.level] = {
                level: nextLevel,
                data: newData,
            }
            return updatedLevels.slice(0, item.level + 1);
        });

        setTimeout(() => {
            levelRefs.current[nextLevel - 1]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
    };

    const handleMouseEnter = (itemId) => {
        setHoveredItems((prev) => ({ ...prev, [itemId]: true }));
    };

    const handleMouseLeave = (itemId) => {
        setHoveredItems((prev) => ({ ...prev, [itemId]: false }));
    };


    return (
        <div
            className="w-full sm:mt-[95px] lg:mt-[95px] xl:mt-[95px] shadow rounded overflow-y-auto h-[85vh] overflow-x-auto"
            style={{
                backgroundColor: 'white',
                direction: arabicAlignMent.rightToLeft,
            }}
        >


            <div className="flex gap-4 p-4" style={{ width: '100%' }}>
                {chartLevels?.map((levelData, index) => (
                    <div
                        key={index}
                        className="shadow rounded py-10 d-flex flex-col items-center"
                        style={{ backgroundColor: darkModeState.checkvalue ? dayTheme.Dbgcolor : darkTheme.Dbgcolor, width: '220px', maxHeight: '100%' }}
                        ref={(el) => (levelRefs.current[index] = el)}
                    >
                        {/* <div className='d-flex justify-end align-center'>
                            {(formDetails?.levelreadonly?.[levelData.level - 1] === false) && (
                                <AddChartofAccount
                                    setLogoutModalExRefreshToken={setLogoutModalExRefreshToken}
                                    parentId={activeItems[levelData.level - 1]}
                                    setInsertData={setInsertData}
                                />
                            )}

                        </div> */}


                        <div className="w-full d-flex justify-end">
                            {formDetails?.levelreadonly?.[levelData.level - 1] === false && (
                                <AddChartofAccount
                                    setLogoutModalExRefreshToken={setLogoutModalExRefreshToken}
                                    parentId={activeItems[levelData.level - 1]}
                                    setInsertData={setInsertData}
                                    chartNameImage={action.find((item) => item.name === "Insert")} // Find the "Insert" action
                                    upadtename="Insert"
                                    formDetails={formDetails}
                                    accountData={null} // No specific account data for "Insert"
                                    setModalOpenState={setModalOpenState}
                                />
                            )}
                        </div>


                        {levelData?.data?.length > 0 ? (
                            levelData?.data.map((item) => (
                                <div className="my-2 flex justify-center" key={item.id} style={{ width: "200px" }}>

                                    <button
                                        className={`w-full d-flex flex-column justify-between rounded p-2 ${loadingLevel === item.level + 1 && activeItems[item.level] === item.id ? 'animate-bounce' : ''}`}
                                        style={{
                                            minHeight: '85px',
                                            maxHeight: 'fitContent',
                                            width: hoveredItems[item.id] ? '100%' : '93%', // Conditional width
                                            backgroundColor: hoveredItems[item.id]
                                                ? darkModeState.checkvalue
                                                    ? dayTheme.DmenuButnColor
                                                    : darkTheme.DmenuButnColor
                                                : activeItems[item.level] === item.id
                                                    ? darkModeState.checkvalue
                                                        ? dayTheme.DmenuButnColor
                                                        : darkTheme.DmenuButnColor
                                                    : 'white',
                                            transition: 'background-color 0.3s ease, width 0.3s ease', // Smooth transition
                                        }}
                                        onMouseEnter={() => handleMouseEnter(item.id)}
                                        onMouseLeave={() => handleMouseLeave(item.id)}
                                        onClick={() => handleItemClick(item)}
                                    >


                                        <div className='flex justify-between w-full'>
                                            <div className="flex justify-start align-start w-10/12 h-full" style={{ fontSize: '11px', fontWeight: 'bolder' }}>

                                                <div>
                                                    {item?.isgroup ? <FolderIcon style={{ fontSize: '35px' }}
                                                        className={`text-amber-300 mr-1`}
                                                    /> : <DescriptionIcon style={{ fontSize: '35px' }} className="text-amber-300 mr-1" />}
                                                </div>
                                                <div><div className='pt-1 text-start'>{item.name}</div>
                                                    <div style={{ fontSize: '10px', fontWeight: 800, textAlign: 'left' }}>{item.code}</div></div>

                                            </div>
                                            <div className={`w-2/12 d-flex justify-center items-center h-full ${activeItems[item.level] === item.id ? 'rotate-[270deg]' : 'rotate-0'}`}>
                                                {item?.isgroup && <ArrowDropDownIcon />}
                                            </div>

                                        </div>


                                        <div className='w-full d-flex justify-end'>
                                            {action?.map((itemActive, index) => (
                                                (formDetails?.levelreadonly?.[levelData.level - 1] === false)
                                                && (itemActive?.name !== "Insert") && (
                                                    <AddChartofAccount
                                                        key={index} // Ensure each element has a unique key
                                                        setLogoutModalExRefreshToken={setLogoutModalExRefreshToken}
                                                        parentId={activeItems[levelData.level - 1]}
                                                        // parentId={activeItems[levelData.level]}
                                                        setInsertData={setInsertData}
                                                        chartNameImage={itemActive}
                                                        formDetails={formDetails}
                                                        upadtename={item.name}
                                                        accountData={item}
                                                        setModalOpenState={setModalOpenState}
                                                    />
                                                )
                                            ))}

                                        </div>

                                    </button>
                                </div>

                            ))
                        ) : (
                            <div className="text-center text-gray-500">No accounts available</div>

                        )}

                        {/* <div className='d-flex justify-end align-center'>
                            {(levelData.level === 4 || levelData.level === 5) && (
                                <AddChartofAccount setLogoutModalExRefreshToken={setLogoutModalExRefreshToken} parentId={activeItems[levelData.level - 1]} setInsertData={setInsertData} />
                            )}
                        </div> */}

                    </div>
                ))}
            </div>
            {hiddenReloadIcon && <BeforeLoadTableData />}
        </div>
    );
}

export default ChartAccounts;
