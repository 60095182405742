import React, { useEffect, useState } from 'react'
import { reduceMenuData } from '../../svgimagejsript';
import { masterFormDesign } from '../../PageStyle/pageStyleVariable';
import { darkTheme, dayTheme } from '../../PageStyle/colorsdarkWhite';
import { useSelector } from 'react-redux';
import SimCardIcon from '@mui/icons-material/SimCard';
import CloseIcon from '@mui/icons-material/Close';

function MasterPayment({ formDetails, formData, handleFieldChange, handleSave, paymodeState, setPayModeState }) {

    const [SvgImages, setSvgImages] = useState([])
    const darkModeState = useSelector((state) => state.darkMode)

    // const [cashOPtion, setCashOPtion] = useState([{
    //     name: "Cash",
    //     image: CashImage
    // }, {
    //     name: "Card",
    //     image: AtmImage
    // }, {
    //     name: "UPI",
    //     image: UPIImage
    // }])


    const handelSvecash = (event) => {
        setPayModeState(false);
        handleSave(event);
    }


    useEffect(() => {
        reduceMenuData().then(data => setSvgImages(data));
    }, [])

    return (
        // <div className='absolute top-0 h-screen d-flex justify-center items-center xl:w-9/12 lg:w-9/12 sm:w-full'>
        <div>
            <div className='shadow' style={{ backgroundColor: "white", margin: "1rem", borderRadius: "10px" }}>
                <div className="pt-3 ps-2 pr-2 pb-2 border-b-2 font-bold">
                    <h1>Amount</h1>
                </div>
                {formDetails?.multipaymode == false && formDetails?.paymodes &&
                    formDetails?.paymodes.map((field, index) => {
                        const imgitem = SvgImages.find((img) => img.name === field?.image);
                        console.log("imgitem", imgitem)
                        return (
                            <div
                                key={field.id}
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    padding: "0.3rem 0.5rem",
                                }}
                            >
                                <div>
                                    <input
                                        type="radio"
                                        id={`radio-${field.id}`}
                                        name="paymentMode"
                                        value={formData[field?.name]}
                                        onChange={(event) => handleFieldChange(event, field)}
                                        className=""
                                    />
                                    <label
                                        htmlFor={`radio-${field.id}`}
                                        className="accountnetlabel pr-1"
                                        style={{ fontWeight: "600", marginLeft: "0.5rem" }}
                                    >
                                        {field?.name}
                                    </label>
                                </div>


                                <div>
                                    {imgitem && (
                                        <img src={imgitem?.image} alt={field?.name} width="30" />
                                    )}
                                </div>

                            </div>)
                    })}

                {formDetails?.multipaymode && formDetails?.paymodes &&
                    formDetails?.paymodes.map((field, index) => {
                        const imgitem = SvgImages.find((img) => img.name === field?.image);
                        console.log("imgitem", imgitem)
                        return (
                            < div >
                                <div>
                                    {formDetails?.multipaymode == false && (
                                        <div className='d-flex justify-center items-center'>
                                            <label
                                                htmlFor={`radio-${field.id}`}
                                                className="accountnetlabel pr-1"
                                                style={{ fontWeight: "600", marginLeft: "0.5rem" }}
                                            >
                                                {field?.name}
                                            </label>
                                            <div>
                                                {imgitem && (
                                                    <img src={imgitem?.image} alt={field?.name} width="30" />
                                                )}
                                            </div>
                                            <input
                                                className="accountnetinput"
                                                type="number"
                                                value={formData[field?.name]}
                                                onChange={(event) => handleFieldChange(event, field)}
                                            // onChange={(e) =>
                                            //     handleChange(field.name, e.target.value, field)
                                            // }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        );

                    })}
                <div
                    className="flex justify-between items-center bg-[#a6a3c9] p-2 text-white font-bold w-full"
                    style={{
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                    }}
                >
                    {formDetails?.multipaymode &&  <div className='w-1/6'>Total</div> }
                    <style>
                        {`
                        .custom-input{
                        text-align:right;
                        border: none; /* Removes border */
                        outline: none;
                        width:100%;
                        }
                        .custom-input::placeholder {
                        color: white;
                        opacity: 1; /* Ensures visibility in Firefox */
                        }

                        .custom-input::-ms-input-placeholder {
                        color: red; /* Targets IE */
                        }

                        .button-Style{
                        
                        }
                        `}
                    </style>
                    <div className='w-2/6'>
                        {formDetails?.multipaymode && <input type="number" className='custom-input text-white bg-[#a6a3c9]' placeholder='0.00' />}
                    </div>
                    <div className='w-3/6 d-flex justify-end'>
                        <button type='submit' className='mx-1' onClick={handelSvecash}><SimCardIcon className={`hover:${masterFormDesign.buttonHoverColor}`} style={{ color: darkModeState.checkvalue ? dayTheme.DlabelTextColor : darkTheme.DlabelTextColor }} /></button>
                        <button className='mx-1' onClick={() => setPayModeState(false)}><CloseIcon className={`hover:${masterFormDesign.buttonHoverColor}`} style={{ color: darkModeState.checkvalue ? dayTheme.DlabelTextColor : darkTheme.DlabelTextColor }} /></button>
                    </div>
                </div>
            </div>


        </div >
    )
}

export default MasterPayment