import { useState } from 'react';
import axios from 'axios';
import { refreshToken } from '../Components/Masters/masterPagefunctions';
const useFetchTaxes = (setLogoutModalExRefreshToken) => {
  const [taxes, setTaxes] = useState([]);
  const [taxRate, setTaxRate] = useState(null);
  const [arrtax, setarrtax] = useState([])
  const [onetaxrow, setonetaxrow] = useState()
  const fetchTaxes = async (nurl,value,name,taxsingle) => {
    
    let url 
    if(nurl){
    url=  nurl.replace(":"+name,value)
    }


    console.log("fetchTaxes url",url)

    const email = sessionStorage.getItem('email');
    const responseobjerct = JSON.parse(sessionStorage.getItem('responseobjerct'));
    const expiresat = responseobjerct?.access_token_expires_at.split(' ')[1];
    const currentTime = new Date().toUTCString().split(' ')[4];

    let response;
    try {
      if (currentTime < expiresat) {
        response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${responseobjerct?.access_token}`,
            identifier: email,
          },
        });
      } else {
        const refresh_token = responseobjerct?.refresh_token;
        await refreshToken(refresh_token, setLogoutModalExRefreshToken);
        await fetchTaxes(url); // Retry with the new token
        return;
      }

      const taxdata = response.data.data;
    
      if(taxdata){
      // setarrtax([...arrtax,{ [name]:value, txntax_id:taxdata.id, txn_taxrate:taxdata.taxrate} ])
      setarrtax((prev) => [
        ...prev,
        { [name]: value, txn_taxid: taxdata.id, txn_taxrate: taxdata.taxrate },
      ]);
      }

      // settaxacc(taxarr)
      console.log("rrtax",arrtax)

      if (taxdata.taxrate) {
        setTaxRate(taxdata.taxrate);
      }

      if(taxsingle){
        setonetaxrow(taxsingle)
      }
      // setTaxes(taxarr);
      // return taxarr
    } catch (err) {
      console.error('Error fetching taxes:', err);
    }
  };
  
  return { fetchTaxes, taxes, taxRate,arrtax ,onetaxrow, setonetaxrow };
};

export default useFetchTaxes;
