import { BaseURL } from "../Components/Masters/masterPagefunctions";

export const initializeFormValues = (fields = [], additionalFields = []) => {
    return [...fields, ...additionalFields].reduce((acc, field) => {
      acc[field.name] = "";
      return acc;
    }, {});
  };


 export const calculateSums = (fields, formValues,isTaxable = true) => {
    return fields
      .filter(field => field.taxable === isTaxable)
      .reduce((acc, field) => {
        const value = formValues[field.name] || 0;
        if (value && field.sign) {
          acc[field.name] = value * field.sign;
        }
        return acc;
      }, {});
  };


  export const hardcode= [ 

    {
        id:2,
        name:"txn_amount",
        label:" Amount",
        taxable:false,
        taxrate:null,
        inputtype:"Number",
        sign:null
        

    },
]


export const taxcode=[
    {
        id:2,
        name:"calculatetax",
        label:"Tax Amount",
        taxable:false,
        taxrate:null,
        inputtype:"Number",
        sign:null,
        // position:0
        

    },
    {
        id:8,
        name:"netamount",
        label:"Net Amount",
        taxable:false,
        taxrate:null,
        type:"number",
        sign:null,
        inputtype:"Number",

    },
]



export const processFormGrids = (formGrids, rows,fetchTaxes) => {
    const getFieldName = [];
    let newurl = '';
    let singlename = '';
    let arrtax = [];

    formGrids.forEach((field) => {
        if (field.triggerfields) {
            singlename = field.name;
            getFieldName.push(singlename);
            field.triggerfields.forEach((trigger) => {
                const triggerdetails = formGrids.find((f) => f.name === trigger);
                if (triggerdetails?.apiarguments) {
                    newurl = `https://${triggerdetails.apiservicename}.${BaseURL}${triggerdetails.apiendpoint}?${triggerdetails.apiarguments}`;
                }
            });
        }
    });

    console.log("getFieldName", getFieldName);
    singlename = getFieldName[0];
    const taxvalue = rows.map((item) => item[singlename]);
    console.log("newurl", newurl);

    taxvalue.forEach((value) => {
        if (value) {
            const res = fetchTaxes(newurl, value, singlename, []);
            // Process or store the response (res) here as needed.
        }
    });

    console.log("grid show arrtax", arrtax);
};



export const updateRowsWithTax = (rows, uniqueData) => {
    return rows.map(row => {
        // Find the matching uniqueData based on txn_accid
        const matchingTax = uniqueData.find(tax => tax.txn_accid === row.txn_accid);

        if (matchingTax) {
            // If a match is found, merge the row with tax info
            const updatedRow = { 
                ...row, 
                txn_taxid: matchingTax.txn_taxid, 
                txn_taxrate: matchingTax.txn_taxrate 
            };

            // If txn_amount exists, calculate txn_tax
            if (updatedRow.txn_amount && !isNaN(updatedRow.txn_amount)) {
                updatedRow.txn_tax = updatedRow.txn_amount * (parseFloat(updatedRow.txn_taxrate) / 100);
            }

            return updatedRow;
        }
        return row; // If no match, return the row as is
    });
};


export const processFormDetails = (formDetails, formData, getAccDataUpdate, selectState,fetchTaxes) => {
    const getFieldName = [];
    let newurl;
    let singlename;
    let taxsingle = [];
    let taxvalue = [];
  
    formDetails?.form_fields?.forEach((field) => {
      if (field.triggerfields) {
        singlename = field.name;
        getFieldName.push(singlename);
  
        field?.triggerfields?.forEach((trigger) => {
          const triggerdetails = formDetails?.form_fields?.find(
            (field) => field.name === trigger
          );
  
          if (triggerdetails?.apiarguments) {
            newurl = `https://${triggerdetails.apiservicename}.${BaseURL}${triggerdetails.apiendpoint}?${triggerdetails.apiarguments}`;
          }
  
          if (selectState.name === "Insert" && formDetails.form_grids == null) {
            taxsingle.push(formData);
            taxvalue = taxsingle.map((item) => item[singlename]);
          } else if (
            selectState.name === "Update" &&
            formDetails.form_grids == null &&
            getAccDataUpdate?.data
          ) {
            console.log("taxsingle getAccDataUpdate", getAccDataUpdate?.data);
            taxsingle.push(getAccDataUpdate?.data[0]);
            taxvalue = taxsingle.map((item) => item[singlename]);
          }
        });
      }
    });
  
    singlename = getFieldName[0];
  
    console.log("taxvalue", taxvalue[0]);
    if (taxvalue[0]) {
      fetchTaxes(newurl, taxvalue[0], singlename, taxsingle);
    }
  
  };


 export  function processTaxData(arrtax, onetaxrow, selectState, formDetails, taxrow) {
    // Filter unique data based on `txn_accid`
    const uniquedata = arrtax.filter((item, index, self) =>
        index === self.findIndex((t) => t.txn_accid === item.txn_accid)
    );

    let result = [];

    if (onetaxrow) {
        // Map `onetaxrow` to include data from `uniquedata`
        result = onetaxrow.map((item) => {
            const matchingData = uniquedata.find(
                (data) => data.txn_accid === item.txn_accid
            );
            return matchingData
                ? { ...item, txn_taxid: matchingData.txn_taxid, txn_taxrate: matchingData.txn_taxrate }
                : item;
        });
    }

    // Update result to include calculated `txn_tax`
    const updatedResult = result.map(item => {
        const txnAmount = parseFloat(item.txn_amount) || 0; // Safely parse txn_amount
        const txnTaxRate = parseFloat(item.txn_taxrate) || 0; // Safely parse txn_taxrate
        const txnTax = txnAmount * (txnTaxRate / 100); // Calculate txn_tax
        return {
            ...item,
            txn_amount: txnAmount,
            txn_taxrate: txnTaxRate,
            txn_tax: txnTax
        };
    });

    // Determine `newRows` based on `selectState` and `formDetails`
    let newRows = [];
    if (selectState.name === "Update" || selectState.name === "Insert") {
        newRows = formDetails.form_grids ? taxrow : updatedResult;
    }

    return newRows;
}
  
  