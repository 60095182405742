import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const PieChart = ({item}) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chartInstance = echarts.init(chartRef.current);

        const option = {
            title: {
                text: `${item?.name}`,
                subtext: null,
                left: "center",
            },
            tooltip: {
                trigger: "item",
            },
            legend: {
                orient: "vertical",
                right: 'right',
                top:'center'
            },
            series: [
                {
                    name: `${item?.name}`,
                    type: "pie",
                    radius: ['50%', '70%'],
                    data: [
                        { value: 1048, name: "Search Engine"},
                        { value: 735, name: "Direct" },
                       ,
                    ],
                    label: {
                        show: false,
                        position: 'center'
                      },
                    color: ["#5fa1d6","#cfe3fc"],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                        label: {
                            show: false,
                            position: 'center'
                          },
                    },
                },
            ],
        };

        chartInstance.setOption(option);

        window.addEventListener("resize", chartInstance.resize);

        return () => {
            window.removeEventListener("resize", chartInstance.resize);
            chartInstance.dispose(); // Cleanup on unmount
        };
    }, []);

    return <div ref={chartRef} className="pt-3" style={{ width: "100%", height: "100%" }} />;
};

export default PieChart;
