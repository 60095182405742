import axios from "axios";
import { selectMenuFunction } from "../../Redux/Reducer/activateMenuSlice";


// master API
export const commanAPI = "https://zerobook.milestone-hosting.cloud"

export const BaseURL = `zerobook.online`
// export const BaseURL = "milestone-hosting.site"
//export const BaseURL = "zerobook.shop"
//export const BaseURL = "zerobook.store"

// comman API Update Delete List Insert
export const commanAactionsAPI = `https://operations.${BaseURL}/api/operation`






export const email = sessionStorage.getItem('email')


// refreshToken

// export const refreshToken = async (refresh_token, setLogoutModalExRefreshToken) => {
//   // debugger
//   try {
//     if (!refresh_token) {
//       throw new Error("No refresh token found");
//     }
//     const responseobjerct = JSON.parse(sessionStorage.getItem('responseobjerct'));
//     const expiresat = responseobjerct?.refresh_token_expires_at.split(' ')[1];
//     const orgid = sessionStorage.getItem("orgid");
//     const email = sessionStorage.getItem("email");

//     const currentTime = new Date().toUTCString().split(' ')[4];

//     let refreshResponse;
//     if (currentTime < expiresat) {
//       refreshResponse = await axios.post(
//         `https://userhub.${BaseURL}/api/v1/refresh-token`,
//         {
//           refresh_token,
//           orgid,
//           email

//         }
//       );
//     } else {
//       setLogoutModalExRefreshToken(true);
//     }
//     // Store the new access token in sessionStorage
//     sessionStorage.setItem("responseobjerct", JSON.stringify(refreshResponse.data));
//     sessionStorage.setItem("token", refreshResponse.data.access_token);
//     // return refreshResponse.data.access_token;
//   } catch (refreshError) {
//     console.error("Token refresh failed:", refreshError);
//     throw refreshError;
//   };
// }



// Create function for fetching Master API data and List Table
// export const fetchData = async (setFormDetails, selectState, sethiddenReloadIcon, setIsValidateRequired, language, setLogoutModalExRefreshToken) => {
//   //------------ set Reaload true-------------
//   // debugger
//   const email = sessionStorage.getItem('email')
//   if (selectState.name === null && selectState.opid === null) {
//     sethiddenReloadIcon(false);
//   } else {
//     sethiddenReloadIcon(true);
//     setIsValidateRequired(false);
//     console.log("Backend", `${commanAactionsAPI}/${selectState.name}/${selectState.opid}/${language}`)
//     try {
//       console.log("Backend values", selectState.name, selectState.opid)
//       const responseobjerct = JSON.parse(sessionStorage.getItem('responseobjerct'));

//       const expiresat = responseobjerct?.access_token_expires_at.split(' ')[1];
//       const currentTime = new Date().toUTCString().split(' ')[4];
//       // console.log("insert List", currentTime, expiresat)
//       // console.log("refrestoken", responseobjerct?.refresh_token, responseobjerct?.refresh_token_expires_at)

//       let response
//       if (currentTime < expiresat) {
//         response = await axios.get(
//           `${commanAactionsAPI}/${selectState.name}/${selectState.opid}/${language}`,
//           {
//             headers: {
//               Authorization: `Bearer ${responseobjerct?.access_token}`,
//               identifier: email
//             },
//           }
//         );
//       } else {
//         console.log("refreshtoken")
//         const refresh_token = responseobjerct?.refresh_token;
//         await refreshToken(refresh_token, setLogoutModalExRefreshToken);
//         // console.log("refreshtokenOne")
//         await fetchData(setFormDetails, selectState, sethiddenReloadIcon, setIsValidateRequired, language, setLogoutModalExRefreshToken)
//       }


//       if (response.status === 200) {
//         //   console.log("FormFiled data", response.data.form);
//         console.log("API Argument First", response.data.form)
//         //   setSearchValue('')
//         if (selectState.name !== 'Report') {
//           setFormDetails(response.data.form);
//         } else {
//           setFormDetails(response.data.reports);
//         }

//         //------------ set Reaload false-------------
//         sethiddenReloadIcon(false);
//       } else {
//         console.error("Request failed with status:", response.status);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   }

// };


export const refreshToken = async (setLogoutModalExRefreshToken) => {
  try {
    // debugger
    const responseObject = JSON.parse(sessionStorage.getItem("responseobjerct"));

    if (!responseObject) {
      throw new Error("No session object found");
    }

    const accessTokenExpiresAt = responseObject?.access_token_expires_at.split(" ")[1];
    const refreshTokenExpiresAt = responseObject?.refresh_token_expires_at.split(" ")[1];
    const refresh_token = responseObject?.refresh_token;
    const currentTime = new Date().toUTCString().split(" ")[4];

    // Check access token expiration
    if (currentTime < accessTokenExpiresAt) {
      return responseObject.access_token; // Access token is still valid
    }

    // Check refresh token expiration
    if (currentTime >= refreshTokenExpiresAt) {
      console.log("Refresh token expired. Logging out...");
      setLogoutModalExRefreshToken(true);
      return null;
    }

    // Refresh token is valid, proceed to get a new access token
    const orgid = sessionStorage.getItem("orgid");
    const email = sessionStorage.getItem("email");

    const refreshResponse = await axios.post(
      `https://userhub.${BaseURL}/api/v1/refresh-token`,
      { refresh_token, orgid, email }
    );

    const { data } = refreshResponse;

    // Store the new tokens in sessionStorage
    sessionStorage.setItem("responseobjerct", JSON.stringify(data));
    sessionStorage.setItem("token", data.access_token);

    return data.access_token;
  } catch (error) {
    console.error("Token refresh failed:", error);
    throw error;
  }
};

export const fetchData = async (
  setComponentRootPath,
  setFormDetails,
  selectState,
  setHiddenReloadIcon,
  setIsValidateRequired,
  language,
  setLogoutModalExRefreshToken,
) => {
  // debugger
  // setComponentRootPath(null)
  try {
    const email = sessionStorage.getItem("email");
    const responseObject = JSON.parse(sessionStorage.getItem("responseobjerct"));

    if (!selectState.name || !selectState.opid) {
      setHiddenReloadIcon(false);
      return;
    }

    setHiddenReloadIcon(true);
    setIsValidateRequired(false);

    // Refresh tokens and get valid access token
    const accessToken = await refreshToken(setLogoutModalExRefreshToken);
    if (!accessToken) {
      return; // Logout modal will be triggered
    }

    // sessionStorage.setItem("selectname",selectState.name);
    // sessionStorage.setItem("selectOpid",selectState.opid);

    // Perform API call with valid access token
    const apiUrl = `${commanAactionsAPI}/${selectState.name}/${selectState.opid}/${language}`;
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        identifier: email,
      },
    });

    if (response.status === 200) {
      const responseType = response?.data?.type?.toLowerCase()
      setComponentRootPath(responseType);
      // const formDetails = selectState.name == "Insert" ? response.data.form :  selectState.name == "List" ?  response.data.form :  selectState.name == "Report" ?  response.data.reports :  response.data.tree ;
      const formDetails = response?.data?.[responseType]
      setFormDetails(formDetails);
    } else {
      console.error("Request failed with status:", response.status);
    }

    setHiddenReloadIcon(false);
  } catch (error) {
    console.error("Error fetching data:", error);
    setHiddenReloadIcon(false);
  }
};








// create groupListData for Master Form Supplier (Dropdown)
export const groupListData = async (gropDropListAPI, setGroupList, setAccountList, field, setLogoutModalExRefreshToken) => {
  const email = sessionStorage.getItem('email')
  try {
    const accessToken = await refreshToken(setLogoutModalExRefreshToken);
    if (!accessToken) {
      return; // Logout modal will be triggered
    }

    let response

    response = await axios.get(gropDropListAPI,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          identifier: email
        },
      }
    );

    console.log("Supplier Dropdown after", gropDropListAPI);
    console.log("supplier Drop response : ", response.data.data);
    setGroupList(response.data.data);
    setAccountList({ accSeleBox: response.data.data, accSelectFileds: field });
  } catch (error) {
    console.error('Error fetching supplier list:', error);
  }
};





// create dropdown API for Master Form (Region) 
export const getRegion = async (APIStateRegion, setRegionList, setLogoutModalExRefreshToken) => {
  const email = sessionStorage.getItem('email')
  try {
    const accessToken = await refreshToken(setLogoutModalExRefreshToken);
    if (!accessToken) {
      return; // Logout modal will be triggered
    }

    let response

    response = await axios.get(APIStateRegion,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          identifier: email
        },
      }
    );

    // console.log("Response data:",`${commanAPI}${filterAPI}`);
    setRegionList(response.data);
  } catch (error) {
    console.error('Error fetching state list:', error);
  }
};

// GetMAsterAccountData(get perticulat data form)(SUP,CUS,REV......)

// export const getMasterAccountDetails = async (formDetails, selectState, setgetAccDataUpdate) => {
//   console.log("FormDetailsMAsterAccount", formDetails?.apiendpoint)
//   console.log("get Update data", `https://${formDetails.apiservicename}.${BaseURL}${formDetails.apiendpoint}/${selectState.userId}`)
//   try {
//     const response = await axios.get(`https://${formDetails.apiservicename}.${BaseURL}${formDetails.apiendpoint}/${selectState.userId}`);
//     console.log("get Update data", `https://${formDetails.apiservicename}.${BaseURL}${formDetails.apiendpoint}/${selectState.userId}`)
//     console.log("response",response.data)
//     const { billingaddress } = response.data.data[0];  
//     if (billingaddress) {
//       const billingAddstring = JSON.stringify(billingaddress);
//       const billingAdd = JSON.parse(billingAddstring);
//       const billingAddsec = JSON.parse(billingAdd);
//       const billingAddThr= JSON.parse(billingAddsec);
//       response.data[0].billingaddress = billingAddThr
//       console.log("Billing address", billingAddThr)
//       setgetAccDataUpdate(response.data);
//     } else {
//       setgetAccDataUpdate(response.data);
//     }
//     //  console.log("getMasterAccountDetails",billingAddressNew);
//   } catch (error) {
//     console.error('Error fetching account Details list:', error);
//   }
// }
export function handlepresave(insertvalue, formDetails, formData) {



  for (const key in formDetails?.form_fields) {
    const field = formDetails.form_fields[key];
    let fieldName

    if (field.jsonref) {
      fieldName = field?.jsonref
    } else {
      fieldName = field?.name
    }
    if (!fieldName || !(fieldName in formData)) continue;

    const value = formData[fieldName];

    if (field?.jsonref) {
      insertvalue[fieldName] = JSON.stringify(value);
    } else if (field?.inputtype === "Number") {
      if (field?.dataformat !== null) {
        const decimalPlaces = (field?.dataformat?.split('.')[1] || '').length;
        insertvalue[fieldName] = parseFloat(value, decimalPlaces);
      }

      else
        insertvalue[fieldName] = parseInt(value, 10);
    } else {

      insertvalue[fieldName] = value;
    }
  }
}


// Function to handle form field inputs, including Number, Date, and JSON refs
export function handleInput(formDetails, response) {
  // debugger
  const fieldName = formDetails.name; // Name from formDetails

  // handle.jsonref
  if (formDetails.jsonref) {
    const jsonField = formDetails.jsonref;



    if (response[jsonField]) {
      try {
        let parsedJson = typeof response[jsonField] == "string" && JSON.parse(response[jsonField])
        console.log("parsedJson", parsedJson);
        if (parsedJson) {
          if (typeof parsedJson !== "string") {
            response[jsonField] = parsedJson;
          } else {
            const stringConvert = JSON.parse(parsedJson)
            response[jsonField] = stringConvert;
            console.log("parsedJson", stringConvert);
          }

          console.log(`Parsed ${jsonField}.${fieldName}:`, parsedJson[fieldName]);
        } else {
          console.warn(`Key ${fieldName} not found in parsed ${jsonField}.`);
        }
      } catch (parseError) {
        console.error(`Error parsing ${jsonField}:`, parseError);
      }
    } else {
      response[jsonField] = {}
      console.warn(`Key ${jsonField} not found in the response data.`);
    }
  }


  // Handle 'Number' input type
  if (formDetails.inputtype === "Number" && response[fieldName] !== undefined) {
    try {
      let originalAmount = parseFloat(response[fieldName]);
      if (!isNaN(originalAmount)) {
        const decimalPlaces = formDetails.decimalplaces || 2;
        response[fieldName] = originalAmount.toFixed(decimalPlaces);
        console.log(`Formatted ${fieldName}:`, response[fieldName]);
      } else {
        console.error(`${fieldName} is not a valid number.`);
      }
    } catch (parseError) {
      console.error(`Error formatting ${fieldName}:`, parseError);
    }
  }

  // Handle 'Date' input type
  if (formDetails.inputtype === "Date" && response[fieldName]) {
    try {
      response[fieldName] = response[fieldName].split(' ')[0]; // Format to date only
      console.log(`Formatted ${fieldName}:`, response[fieldName]);
    } catch (parseError) {
      console.error(`Error formatting ${fieldName}:`, parseError);
    }
  }
}

// Main function to get Master Account Details
export const getMasterAccountDetails = async (formDetails, selectState, setgetAccDataUpdate, setgetAccDataUpdateCopy, setLogoutModalExRefreshToken) => {
  // debugger
  const email = sessionStorage.getItem('email');

  try {
    // Construct the URL
    const url = `https://${formDetails.apiservicename}.${BaseURL}${formDetails.apiendpoint}?id=${selectState.userId}`;
    console.log("Request URL:", url);

    // Check if the token is still valid
    const accessToken = await refreshToken(setLogoutModalExRefreshToken);
    if (!accessToken) {
      return; // Logout modal will be triggered
    }
    let response;

    // Token validation

    response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        identifier: email,
      },
    });


    // Extracting data
    const data = response.data.data[0];
    const olddata = { ...data }
    console.log("olddata", olddata)
    setgetAccDataUpdateCopy(olddata);
    console.log("formDetails:", formDetails);
    console.log("data before handling inputs:", data);

    const res = formDetails.form_fields.forEach((formField) => handleInput(formField, data));
    console.log("my result", res)

    console.log("data after handling inputs:", data);

    // Parse billing address if present
    // if (data.billingaddress) {
    //   try {
    //     data.billingaddress = JSON.parse(JSON.parse(data.billingaddress));
    //     console.log("Parsed Billing Address:", data.billingaddress);
    //   } catch (parseError) {
    //     console.error("Error parsing billing address:", parseError);
    //   }
    // }

    // Update the state with the fetched data
    console.log("API Response:", response.data);
    setgetAccDataUpdate(response.data);



  } catch (error) {
    console.error('Error fetching account details:', error);
  }
};











// create function for implementing Tab button function for Enter key press

export const handleEnterKeyPress = (event, currentField, formDetails, filterTextid) => {
  if (event.key === 'Enter') {
    event.preventDefault();

    // Ensure the form fields are sorted by position
    const sortedFormFields = formDetails.form_fields.sort(
      (a, b) => a.position - b.position
    );

    const nextFieldIndex = sortedFormFields.findIndex(
      (field) => field.tabid === filterTextid && field.position > currentField.position
    );

    if (nextFieldIndex !== -1) {
      const nextField = sortedFormFields[nextFieldIndex];
      const nextFieldElement = document.getElementById(nextField.id);


      if (nextFieldElement) {
        // Check the type of the next field and focus accordingly
        switch (nextField.inputtype) {
          case 'Text':
          case 'Number':
          case 'Date':
            nextFieldElement.focus();
            // nextFieldElement.click();
            nextFieldElement.select();
            break;

          case 'Dropdown':
            // nextFieldElement.click();
            nextFieldElement.focus();
            // nextFieldElement.select();
            break;
          // Add more cases if needed for other field types
          default:
            break;
        }
      }
    }
  }
};



// // create function for Enter function
export const handleEnterKeyPressMAaster = (event, currentField, formDetails) => {
  if (event.key === 'Enter') {
    event.preventDefault();

    // Ensure the form fields are sorted by position
    const sortedFormFields = formDetails.form_fields.sort(
      (a, b) => a.position - b.position
    );

    const nextFieldIndex = sortedFormFields.findIndex(
      (field) =>
        field.tabid === null &&
        field.position > currentField.position &&
        field.readonly === false
    );

    if (nextFieldIndex !== -1) {
      const nextField = sortedFormFields[nextFieldIndex];
      const nextFieldElement = document.getElementsByName(nextField.name)[0]
      const currentFieldElement = document.getElementsByName(currentField.name)[0];
      console.log("Previous", currentFieldElement)

      // Check if the next field is required and its value is not null or empty
      if (!(currentField.required === true && (currentFieldElement.value == null || currentFieldElement.value.trim() == ''))) {
        if (nextFieldElement) {
          nextFieldElement.focus();
          console.log("nextfiledss", nextFieldElement)
        }
      }
    }
  }
};






// export const handleEnterKeyPressMAaster = (event, currentField, formDetails) => {
//   if (event.key === 'Enter') {
//     event.preventDefault();

//     // Find the next field to focus on
//     const nextField = findNextField(currentField, formDetails);
//     console.log("ksadks", nextField)

//     // Focus on the next field if found
//     if (nextField) {
//       // Focus on the Select component if it's the next field
//       console.log("inside", nextField)
//       console.log("type", nextField.inputtype);
//       const currentFieldElement = document.getElementsByName(currentField.name)[0];
//       const varTag = document.getElementsByName(nextField.name)[0]
//       if (nextField.inputtype === "Dropdown") {
//         // Check if the ref exists and if the focus method is available
//         console.log("inside after", nextField)

//         console.log("current filed", currentFieldElement)
//         console.log("selfRef", varTag)
//         if (!(currentField.required === true && (currentFieldElement.value == null || currentFieldElement.value.trim() == ''))) {
//           if (varTag) {
//             varTag.focus();
//             console.log("select Reference", varTag);
//           }
//         }
//       } else {
//         // Focus on the next input field
//         const nextFieldElement = document.getElementById(nextField.name);
//         // current field
//         console.log("current filed567667", currentFieldElement)
//         if (!(currentField.required === true && (currentFieldElement.value == null || currentFieldElement.value.trim() == ''))) {
//           if (varTag) {
//             varTag.focus();
//             console.log("ullil", nextFieldElement);
//             console.log(nextField.name);
//           }
//         }
//       }
//     }
//   }

// };








// // Helper function to find the next field to focus on
// const findNextField = (currentField, formDetails) => {
//   // Ensure the form fields are sorted by position
//   const sortedFormFields = formDetails.form_fields.sort(
//     (a, b) => a.position - b.position
//   );

//   // Find the index of the current field
//   const currentFieldIndex = sortedFormFields.findIndex(
//     (field) => field.id === currentField.id
//   );

//   // Find the next field based on tabindex
//   let nextFieldIndex = -1;
//   for (let i = currentFieldIndex + 1; i < sortedFormFields.length; i++) {
//     const field = sortedFormFields[i];
//     if (field.tabid === null && field.readonly === false) {
//       nextFieldIndex = i;
//       break;
//     }
//   }

//   // Return the next field if found
//   if (nextFieldIndex !== -1) {
//     const nextField = sortedFormFields[nextFieldIndex];
//     // Set the type property based on field type
//     const type = nextField.inputType === 'Dropdown' ? 'Dropdown' : 'input';
//     return { ...nextField, type };
//   }

//   // Return null if no next field found
//   return null;
// };







// List Table Data (Master Data Filed)
export const getTableListItems = async (formDetails, setTableItemList, setMenuLoaded, setLogoutModalExRefreshToken) => {

  const apiendpoint = formDetails?.apiendpoint;
  const apiArgumenty = formDetails?.apiarguments?.[0];
  const apiServicename = formDetails?.apiservicename
  setMenuLoaded(true)

  console.log("Table Items", `https://${apiServicename}.${BaseURL}${apiendpoint}?${apiArgumenty}`)
  const email = sessionStorage.getItem('email')
  try {
    const accessToken = await refreshToken(setLogoutModalExRefreshToken);
    if (!accessToken) {
      return; // Logout modal will be triggered
    }
    let response
    response = await axios.get(
      `https://${apiServicename}.${BaseURL}${apiendpoint}?${apiArgumenty}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          identifier: email
        },
      }
    );

    if (response.status === 200) {
      setTableItemList(response.data);
      setMenuLoaded(false)
    } else {
      console.error("Request failed with status:", response.status);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    const errorData = {
      name: '',
      active: []
    }
    setTableItemList(errorData);
    setMenuLoaded(false);
  }
};



// export const deleteTableDatas = async()=>{

//   try{
//     const response = await axios.delete(
//       `${commanAPI}/form/`
//   )
//   }catch(error){
//     console.error("error:",error)

//   }
// }

// Delete function for Master Form

export const handleDeleteAPI = async (formDetails, selectState, sethiddenReloadIcon, setSaveButtonDisabled, dispatch, setdisplayCustomAlertBox, setLogoutModalExRefreshToken) => {
  const email = sessionStorage.getItem('email')
  const apiEndpoint = formDetails.apiendpoint;
  const apiArgumenty = formDetails.apiarguments[0];
  const apiservicename = formDetails.apiservicename;
  setSaveButtonDisabled(true);
  sethiddenReloadIcon(true);
  setdisplayCustomAlertBox(false);
  console.log("DeleteedID", selectState);
  let getId
  if (selectState.userId === selectState.deleteId) {
    getId = selectState.userId
  } else {
    getId = selectState.deleteId
  }
  try {
    const accessToken = await refreshToken(setLogoutModalExRefreshToken);
    if (!accessToken) {
      return; // Logout modal will be triggered
    }
    let response

    response = await axios.delete(
      `https://${apiservicename}.${BaseURL}${apiEndpoint}?${apiArgumenty}&id=${getId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          identifier: email
        },
      }
    );


    if (response.status === 200) {
      // window.confirm(response.data.message);
      setSaveButtonDisabled(false);
      sethiddenReloadIcon(false);
      window.addEventListener('click', dispatch(selectMenuFunction({ name: 'List', opid: selectState.opid })));
      // Assuming you want to log the response data
    } else {
      console.error("Request failed with status:", response.status);
    }
  } catch (error) {
    console.error("Error deleting data:", error);
  }
};
