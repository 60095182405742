import { createSlice } from '@reduxjs/toolkit';

const taxRowsSlice = createSlice({
    name:'taxRows',
    initialState:{
        taxrows:[]
    },
    reducers:{
        setTaxRows: (state, action)=> {
            state.taxrows = action.payload
        },
    }

});

export const { setTaxRows} = taxRowsSlice.actions;

export default taxRowsSlice.reducer
