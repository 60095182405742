import React from "react";
import PieChart from "./PieChart";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import Dashboard from './dashoard.json';
import cash from '../../asset/DashboardIcon/img/icons/1.svg'
import sale from '../../asset/DashboardIcon/img/icons/2.svg'
import purchase from '../../asset/DashboardIcon/img/icons/3.svg'
import expense from '../../asset/DashboardIcon/img/icons/4.svg'
import bagroundimage from '../../asset/DashboardIcon/img/1.jpg'
import bagroundimagetwo from '../../asset/DashboardIcon/img/2.jpg'
import bagroundimagethree from '../../asset/DashboardIcon/img/3.jpg'
import BeforeLoadTableData from "../CommanCoponent/BeforeLoadTableData";


function MasterDashBoard({ hiddenReloadIcon, }) {


    const dashboardImage = [
        { name: "Cash Receipt", image: cash, backgroundimage: bagroundimage }, { name: "Today Sales", image: sale, backgroundimage: bagroundimage }, { name: "Today Purchase", image: purchase, backgroundimage: bagroundimage }, { name: "Total Expense", image: expense, backgroundimage: bagroundimage }, { name: "Unsetteld Debit", image: null, backgroundimage: bagroundimagetwo }, { name: "Unsetteld Credit", image: null, backgroundimage: bagroundimagethree }
    ]







    console.log("dashboard", Dashboard);

    return (
        <div className="mt-[100px]">
            <h1 className="text-gray-[#676767] m-3" style={{fontSize:'23px',fontWeight:"600"}}>{Dashboard.dashboard.name}</h1>
            <div
                className="h-[78vh] overflow-y-auto p-3"
                style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(${Dashboard.dashboard.columncount}, minmax(100px, 1fr))`, // Responsive columns
                    gridAutoRows: "minmax(100px, auto)", // Flexible row heights
                    gap: `${Dashboard.dashboard.columngap}rem`, // Grid gap
                }}
            >
                {Dashboard.dashboard.widgets
                    .sort((a, b) => a.position - b.position)
                    .map((item) => {
                        const bgimg = dashboardImage.find((bgimg) => bgimg.name === item.name);
                        return (
                            <div
                                key={item.position}
                                style={{
                                    gridColumn: `span ${item.columnspan}`,
                                    gridRow: `span ${item.rowspan}`,
                                    backgroundImage: bgimg ? `url(${bgimg.backgroundimage})` : "none",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                }}
                                className="bg-white rounded shadow text-gray-900 text-lg font-bold"
                            >
                                {item.type === "piechart" ? (
                                    <PieChart item={item} />
                                ) : item.type === "linechart" ? (
                                    <LineChart item={item} />
                                ) : item.type === "barchart" ? (
                                    <BarChart item={item} />
                                ) : (
                                    <div className="relative">
                                        <img src={bgimg?.image} className="absolute top-[-22px]" alt="" width={50} srcset="" />
                                        <div>
                                            <div>
                                                <h2 className="text-center pt-3">{item.name}</h2>
                                                <h1 className="text-center" style={{ fontSize: '45px' }}>{item.amount}</h1>
                                            </div>
                                        </div>

                                    </div>

                                )}
                            </div>
                        );


                    })}
                {hiddenReloadIcon && <BeforeLoadTableData />}
            </div>
        </div>

    );
}

export default MasterDashBoard;
