import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const LineChart = ({item}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    // Initialize chart
    const myChart = echarts.init(chartRef.current);

    // Chart configuration
    const option = {
      title: {
        text: `${item.name}`,
        subtext: null,
        left: "center",
      },
      xAxis: {
        type: "category",
        data: ["16h", "17h", "18h", "19h", "20h","21h","22h","23h","24h","25h","26h"],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: [10,60,40,50,40,60,80,60,70,50,70],
          type: "line",
          smooth: true, // Makes the line smooth
        },
      ],
    };

    // Set the options
    myChart.setOption(option);

    // Handle window resize
    const handleResize = () => myChart.resize();
    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
      myChart.dispose();
    };
  }, []);

  return (
    <div
    className="pt-3"
      ref={chartRef}
      style={{ width: "100%",height:"100%"}} // Set fixed height
    />
  );
};

export default LineChart;
