import axios from "axios";
import { BaseURL,refreshToken } from "../Masters/masterPagefunctions";





export const getMenuItems = async (setMenuItems,setLogoutModalExRefreshToken) => {
  const userid = sessionStorage.getItem('userid');
  const email = sessionStorage.getItem('email')
  try {
    const accessToken = await refreshToken(setLogoutModalExRefreshToken);
    if (!accessToken) {
      return; // Logout modal will be triggered
    }
    let response
      response = await axios.get(
        `https://operations.${BaseURL}/api/menus/${userid}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            identifier: email
          },
        }
      );
   
    if (response.status === 200) {
      console.log("Menu data", response.data);
      setMenuItems(response.data)
    } else {
      console.error("Request failed with status:", response.status);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};