import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const BarChart = ({item}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);

    const option = {
      title: {
        text: `${item.name}`,
        subtext: null,
        left: "center",
      },
      backgroundColor: "#f5f5f5", // Light gray background
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        left: "5%",
        right: "5%",
        bottom: "10%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        axisLabel: {
          color: "#333", // X-axis label color
        },
        axisLine: {
          lineStyle: {
            color: "#43418e", // X-axis line color
          },
        },
      },
      yAxis: {
        type: "value",
        min: 0, // Minimum value
        max: 300,
        interval:100, // Maximum value (Customize this)
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: "black",
          },
        },
        axisLabel: {
          color: "#666", // Y-axis label color
        },
        axisLine: {
          lineStyle: {
            color: "#999", // Y-axis line color
          },
        },
      },
      series: [
        {
          data: [120, 200, 150, 80, 70, 110, 130],
          type: "bar",
          barWidth: "50%", // Adjust bar width
          itemStyle: {
            color: "#5fa1d6", // Use a single color for all bars
            borderRadius: [5, 5, 0, 0], // Rounded top corners
          },
          emphasis: {
            itemStyle: {
              color: "#cfe3fc", // Highlight bar color on hover
            },
          },
        },
      ],
      
    };

    chartInstance.setOption(option);

    window.addEventListener("resize", chartInstance.resize);

    return () => {
      window.removeEventListener("resize", chartInstance.resize);
      chartInstance.dispose(); // Clean up on unmount
    };
  }, []);

  return <div ref={chartRef} className="pt-3" style={{ width: "100%", height: "100%" }} />;
};

export default BarChart;
