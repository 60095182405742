import ChartAccounts from "./Components/ChartAccounts/ChartAccounts"
import MasterDashBoard from "./Components/Dashboard/MasterDashBoard"
import ChooseCompany from "./Components/Login/ChooseCompany"
import LoginAr from "./Components/Login/LoginAr"
import LoginDemo from "./Components/Login/LoginDemo"
import LoginEng from "./Components/Login/LoginEng"
import LoginMal from "./Components/Login/LoginMal"
import SignInComponents from "./Components/Login/SignInComponents"
import NavMenu from "./Components/NavBar/NavMenu"
import MasterDashboard from "./Pages/masterDashboard"

export const cmpPath =[
    {
        componentPath:'/nav',
        componentElement:<NavMenu/>
    },
    {
        componentPath:'/ar',
        componentElement:<LoginAr/>
    },
    {
        componentPath:'/ml',
        componentElement:<LoginMal/>
    },
    {
        componentPath:'/login',
        componentElement:<LoginEng/>
    },
    {
        componentPath:'/Danabook',
        componentElement:<MasterDashboard/>
    },
    {
        componentPath:'/demo',
        componentElement:<LoginDemo/>
    },
    {
        componentPath: '/',
        componentElement: <SignInComponents />
    },
    {
        componentPath: '/company',
        componentElement: <ChooseCompany/>

    },
    {
        componentPath: '/chartAccounts',
        componentElement: <ChartAccounts/>

    }


]
 